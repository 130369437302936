/* eslint no-use-before-define: 0 */
import React, { useEffect, useState } from "react";
import Table from "antd/es/table";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Radio from "antd/es/radio";
import DatePicker from "antd/es/date-picker";
import Modal from "antd/es/modal";
import moment from "moment";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import Page6Cards from "../layouts/Page6Cards";
import SearchInput from "../inputs/SearchInput";
import Greeting from "../layouts/Greeting";
import ServiceWidget from "../layouts/ServiceWidget";
import supportIcon from '../../assets/images/support-icon.svg'

import {
  DashboardMainStatistics,
  DashboardMdaRequestCount,
  RequestLogs,
  RequestLogsMail,
  FilterRequestLogs,
  UpdateRequestItem,
  GetModuleLink,
  DashboardMainStatisticsForPA,
  DashboardMdaRequestCountForPA,
  RequestLogsForPA,
  FilterRequestLogsForPA,
  UpdateRequestItemForPA,
  GetModuleLinkForPA,
} from "../../utility/fetch";
import Notification from "../../utility/notification";
import { moduleNameData } from "../columns/requestLogColumn";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination, Select } from "antd";
import parse from "html-react-parser";
import axios from "axios";
import { BiDownArrow } from "react-icons/bi";
import Swal from "sweetalert";
const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";

const approvalEnabledMdas = [
  "leave plan",
  "leave request",
  "training management",
  "general request",
  "grievance",
  "memo manager",
  "expense",
  "asset management",
];

const BASE_API_URL = `https://erpapi.edostate.gov.ng/services/api`;
// const BASE_API_URL = `https://edogoverp.com/services/api`;

const Dashboard = ({ history }) => {
  const [title, setTitle] = useState("");
  const [placement, SetPlacement] = React.useState("Days");
  const [receivedMemos, setReceivedMemos] = useState(0);
  const [treateddMemos, setTreatedMemos] = useState(0);
  const [pendingMemos, setPendingMemos] = useState(0);
  const [approvedMemos, setApprovedMemos] = useState(0);
  const [rejectedMemos, setRejectedMemos] = useState(0);
  const [searchText, setSearchText] = useState();
  const [cancel, setCancel] = useState(false);
  const [searchByMda, setSearchByMda] = useState(false);
  const [mdaRequests, setMdaRequests] = useState(0);
  const [requestLogs, setRequestLogs] = useState();
  const [loading, setLoading] = useState(false);
  const [allMemos, setAllMemos] = useState(true);
  // const [allMemos, setallMemos] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [details, setDetails] = useState("");
  const [activeMemo, setActiveMemo] = useState("pending");
  const [totalElement, setTotalElement] = useState();
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [status, setStatus] = useState("pending");
  const [mdas, setMdas] = useState([]);
  const [selectedMdas, setSelectedMdas] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [govRequest, setGovRequest] = useState(false);
  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.width = "7rem";
    e.currentTarget.style.opacity = "1";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.width = "4rem";
    e.currentTarget.style.opacity = "0.8";
  };
  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  const showAlert = (message) => {
    Swal({
      title: "Warning!!",
      text: message,
      icon: "error",
      button: "Cancel!",
    });
  };

  const getLiscence = async () => {
    showAlert(`Your EdoGov license has expired`);
      // on 31/12/2024


    // axios
    //   .get("https://erpapi.edostate.gov.ng/licensingapi/api/Notifications")
    //   .then((response) => {
    //     console.log(response?.data?.data);
    //     if (response?.data?.data?.isActive) {
    //       showAlert(response?.data?.data?.reminderMessage);
    //     }
    //   })
    //   .catch((error) => {});
  };

  const getMdas = () => {
    axios.get(`${BASE_API_URL}/ServiceManagement/get_mdas`).then((response) => {
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdas(roleList);
    });
  };

  useEffect(() => {
    // getLiscence();
    allMemos ? fetchRequestLogs() : fetchRequestLogsMail();
  }, [current, limit, status, searchText, govRequest, cancel, allMemos]);

  const filterLogs = (activeMemo) => {
    setActiveMemo(activeMemo);
    setLimit(20);
    setCurrent(1);
    setStatus(activeMemo);
    // if (activeMemo.toLowerCase() != "received") {
    //   const result = allLogs.filter(
    //     (m) => m.status.toLowerCase() == activeMemo.toLowerCase()
    //   );
    //   setRequestLogs(result);
    // } else {
    //   setRequestLogs(allLogs);
    // }
  };

  const onChangeDate = (value) => {
    console.log(value);
    if (value) {
      let from = value[0]?.format(dateFormat);
      let to = value[1]?.format(dateFormat);
      setFromDate(from);
      setToDate(to);
      filterDate(from, to);
    } else clearRange();
  };
  const filterDate = async (start, end) => {
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
        mdas: selectedMdas,
      };
      const result = await (govRequest
        ? FilterRequestLogsForPA(payload, start, end)
        : FilterRequestLogs(payload, start, end));

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
  };

  const filterMdas = async (selectedMdas) => {
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
        mdas: selectedMdas,
      };
      const result = await (govRequest
        ? FilterRequestLogsForPA(payload, fromDate, toDate)
        : FilterRequestLogs(payload, fromDate, toDate));

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
  };

  const filterbyTitle = async (searchText) => {
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
        mdas: selectedMdas,
      };
      const result = await (govRequest
        ? FilterRequestLogsForPA(payload, fromDate, toDate)
        : FilterRequestLogs(payload, fromDate, toDate));

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
  };

  const fetchRequestLogsMail = async () => {
    // setLoading(true);
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
      };
      const result = await // govRequest
      // ? RequestLogsForPA(payload)
      // : RequestLogsMail(payload));
      RequestLogsMail(payload);

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchRequestLogs = async () => {
    // setLoading(true);
    try {
      const payload = {
        pageNumber: current,
        status,
        queryFilter: searchText,
        pageSize: limit,
      };
      const result = await (govRequest
        ? RequestLogsForPA(payload)
        : RequestLogs(payload));

      if (result?.data?.statusCode === "00") {
        setRequestLogs(
          result?.data?.result?.map((request) => ({
            ...request,
            lastUpdated: moment(request?.lastUpdated)?.format("LLLL"),
          }))
        );
        let tot = result?.headers["x-pagination"];
        let res = JSON.parse(tot);
        setTotalElement(res?.TotalCount);
        setCurrent(res?.CurrentPage);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    // setLoading(false);
  };

  const fetchDashboardMdaRequestCount = async () => {
    setLoading(true);
    try {
      const result = await (govRequest
        ? DashboardMdaRequestCountForPA()
        : DashboardMdaRequestCount());
      if (result?.data?.statusCode === "00") {
        setMdaRequests(result?.data?.result);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  // const fetchDashboardMdaRequestCountForPA = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await DashboardMdaRequestCountForPA();
  //     if (result?.data?.statusCode === "00") {
  //       setMdaRequests(result?.data?.result);
  //     }
  //   } catch (ex) {
  //     console.log(ex.Response);
  //   }
  //   setLoading(false);
  // };

  const fetchDashboardMainStatistics = async () => {
    setLoading(true);
    try {
      const result = await (govRequest
        ? DashboardMainStatisticsForPA()
        : DashboardMainStatistics());

      if (result?.status === 200) {
        const memo = result?.data;
        setReceivedMemos(memo?.receivedMemos);
        setTreatedMemos(
          Number(memo?.approvedMemos) + Number(memo?.rejectedMemos)
        );
        setPendingMemos(memo?.pendingMemos);
        setApprovedMemos(memo?.approvedMemos);
        setRejectedMemos(memo?.rejectedMemos);
      }
    } catch (ex) {
      console.log(ex.Response);
    }
    setLoading(false);
  };

  // const fetchDashboardMainStatisticsForPA = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await DashboardMainStatisticsForPA();
  //     if (result?.status === 200) {
  //       const memo = result?.data;
  //       setReceivedMemos(memo?.receivedMemos);
  //       setTreatedMemos(
  //         Number(memo?.approvedMemos) + Number(memo?.rejectedMemos)
  //       );
  //       setPendingMemos(memo?.pendingMemos);
  //       setApprovedMemos(memo?.approvedMemos);
  //       setRejectedMemos(memo?.rejectedMemos);
  //     }
  //   } catch (ex) {
  //     console.log(ex.Response);
  //   }
  //   setLoading(false);
  // };

  const updateRequestItemStatus = async (payload) => {
    setLoading(true);
    try {
      const result = await UpdateRequestItem([payload]);
      if (result?.data?.statusCode == "00") {
        Notification({
          title: `${payload?.status} Successful!`,
          message: `${result?.data?.apiMessage}`,
          type: "success",
        });
        fetchRequestLogs();
        fetchDashboardMainStatistics();
        fetchDashboardMdaRequestCount();
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  const RedirectToModuleLink = async (payload) => {
    setLoading(true);
    try {
      const result = await (!govRequest
        ? GetModuleLink([payload])
        : GetModuleLinkForPA([payload]));
      if (result?.data?.statusCode == "00") {
        window.location.href = `${
          result?.data?.url
        }${result?.data?.address.replace(
          "https://erpapi.edostate.gov.ng?",
          "?"
        )}`;
      } else {
        Notification({
          title: "Error!",
          message: `${result?.data?.apiMessage}`,
          type: "danger",
        });
      }
    } catch (ex) {
      Notification({
        title: "Error!",
        message: `${ex.response?.data?.apiMessage}`,
        type: "danger",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(+localStorage.getItem("userId"));
    if (!govRequest) {
      getMdas();
      fetchDashboardMainStatistics();
      fetchDashboardMdaRequestCount();
    }
    setActiveMemo("pending");
  }, [govRequest]);

  useEffect(() => {
    // getMdas();

    if (govRequest) {
      fetchDashboardMainStatistics();
      fetchDashboardMdaRequestCount();
    }
    // setActiveMemo("pending");
  }, [govRequest]);

  const menu = (record) => (
    <Menu>
      {/* {record.status?.toLowerCase() == "pending" &&
        approvalEnabledMdas.includes(record?.moduleName?.toLowerCase()) && (
          <>
            <Menu.Item
              onClick={() => {
                record.status = "7";
                updateRequestItemStatus(record);
              }}
            >
              <a rel="noopener noreferrer" href="#">
                Approve
              </a>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                record.status = "8";
                updateRequestItemStatus(record);
              }}
            >
              <a rel="noopener noreferrer" href="#">
                Decline
              </a>
            </Menu.Item>
          </>
        )} */}

      <Menu.Item
        onClick={() => {
          record.status = "0";
          RedirectToModuleLink(record);
        }}
      >
        <a rel="noopener noreferrer" href="#">
          View
        </a>
      </Menu.Item>
    </Menu>
  );

  const mdaRequestsColumns = [
    {
      title: (
        <div>
          {" "}
          <strong className="family m-r-10">MDAs Request</strong>
          {/* <button className="rounded family">
            <strong>Show All</strong>
          </button> */}
        </div>
      ),
      dataIndex: "mdaName",
      //   width: '55%',
      editable: false,
    },
    {
      title: <strong className="family">Received</strong>,
      dataIndex: "received",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Treated</strong>,
      dataIndex: "treated",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Pending</strong>,
      dataIndex: "pending",
      width: "10%",
    },
    {
      title: <strong className="family">Approved</strong>,
      dataIndex: "approved",
      width: "10%",
    },
  ];

  const displayTruncatedDetails = (details, number) => {
    if (details.length > number) {
      return `${details.substring(0, 40)}...`;
    } else {
      return details;
    }
  };

  const requestLogsColums = [
    {
      title: <strong className="family">Request Title</strong>,
      dataIndex: "requestType",
      width: "15%",
      editable: false,
    },
    {
      title: <strong className="family">Request Initiator</strong>,
      dataIndex: "requestInitiator",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Module</strong>,
      dataIndex: "moduleName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Agency/MDA</strong>,
      dataIndex: "mdaName",
      width: "10%",
      editable: false,
    },
    {
      title: <strong className="family">Request Details</strong>,
      // dataIndex: "requestDetails",
      render: (item) => (
        <p
          style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setDetails(item?.requestDetails);
          //   setModalVisible(true);
          // }}
        >
          {item?.requestDetails
            ? parse(displayTruncatedDetails(item?.requestDetails, 20))
            : ""}
        </p>
      ),
      width: "20%",
      align: "center",
    },
    {
      title: <strong className="family">Last Updated</strong>,
      dataIndex: "lastUpdated",
      width: "5%",
    },
    {
      title: <strong className="family">Status</strong>,
      dataIndex: "status",
      width: "5%",
    },
    {
      title: <strong className="family">Action</strong>,
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown
              onClick={(event) => event.stopPropagation()}
              overlay={menu(record)}
              trigger={["click"]}
              placement="bottom"
            >
              <Button style={{ backgroundColor: "#1C811C", color: "white" }}>
                Select
              </Button>
            </Dropdown>
          </Space>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Dashboard";
    setTitle("Dashboard");
  }, []);

  const handleChange = (value) => {
    setSelectedMdas(value);
    filterMdas(value);
  };

  const clearMdas = () => {
    filterMdas([]);

    // setSelectedMdas([]);
    // setCancel(!cancel);
  };

  const clearRange = () => {
    fetchRequestLogs();
    setFromDate("");
    setToDate("");

    // setSelectedMdas([]);
    // setCancel(!cancel);
  };

  function throttleSearch(func, delay) {
    let timerId;

    return function (...args) {
      if (!timerId) {
        // If there's no pending timer, call the search function immediately
        func(...args);

        // Set a new timer to prevent further calls for the specified delay
        timerId = setTimeout(() => {
          timerId = null;
        }, delay);
      }
    };
  }

  // Example usage:
  const throttledSearch = throttleSearch(filterbyTitle, 300);

  const menuSearch = (
    <Menu>
      <Menu.Item onClick={() => setSearchByMda(!searchByMda)} key="1">
        Request Titile & Initiator
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setSearchByMda(!searchByMda)}>
        MDA
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="w-100">
      {loading ? (
        <div>
          <Loader type="ThreeDots" color="green" height={100} width={100} />
        </div>
      ) : (
        <>
          <Greeting />
          <div className="flex space-between w-100 m-b-50">
            <Page6Cards
              cardTitle="Recieved Requests"
              count={receivedMemos}
              onClick={() => filterLogs()}
              isActive={!activeMemo}
            />
            {/* <Page6Cards
              cardTitle="Treated Requests"
              count={treateddMemos}
              onClick={() => fetchRequestLogs("Treated")}
              isActive={activeMemo == "Treated"}
            /> */}
            <Page6Cards
              cardTitle="Pending Requests"
              count={pendingMemos}
              onClick={() => filterLogs("pending")}
              isActive={activeMemo == "pending"}
            />
            <Page6Cards
              cardTitle="Approved Requests"
              count={approvedMemos}
              onClick={() => filterLogs("approved")}
              isActive={activeMemo == "approved"}
            />
            <Page6Cards
              cardTitle="Declined Requests"
              count={rejectedMemos}
              onClick={() => filterLogs("declined")}
              isActive={activeMemo == "declined"}
            />
          </div>

          {/* {
            // +localStorage.getItem('userId') == 253 &&

            +localStorage.getItem("userId") == 3509 && (
              <div
                className="w-100 flex"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <Button
                  style={{
                    width: "500px",
                    height: "60px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginRight: "20px",
                    backgroundColor: !govRequest ? "lightgreen" : "#fff",
                    backgroundColor: !govRequest ? "lightgreen" : "#fff",
                  }}
                  onClick={() => setGovRequest(false)}
                >
                  My Requests
                </Button>
                <Button
                  style={{
                    width: "500px",
                    height: "60px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginRight: "20px",
                    backgroundColor: govRequest ? "lightgreen" : "#fff",
                  }}
                  onClick={() => setGovRequest(true)}
                >
                  Govenors Requests
                </Button>
              </div>
            )
          } */}

          <div className="flex space-between m-b-20">
            <RangePicker
              onClear={() => setCancel(!cancel)}
              onChange={onChangeDate}
            />
            <div className="flex">
              <Dropdown
                style={{ padding: "50px" }}
                overlay={menuSearch}
                placement="bottomLeft"
              >
                <Button
                  style={{
                    height: "50px",
                    marginRight: "5px",
                    backgroundColor: "#1C811C",
                    color: "white",
                  }}
                >
                  Search by {searchByMda ? "Text" : "MDA(s)"} <DownOutlined />
                </Button>
              </Dropdown>
              {!searchByMda ? (
                <div className={`search-input`}>
                  <Select
                    mode="multiple"
                    // mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onClear={clearMdas}
                    placeholder="Please select one or more Mdas"
                    onChange={handleChange}
                    // onSelect={}
                  >
                    {mdas.map((item) => (
                      <Option
                        key={item.value}
                        className="customized_input"
                        value={item.value}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <Input
                  style={{
                    width: "100%",
                    minWidth: "350px",
                  }}
                  placeholder="Search By Text"
                  onChange={(e) => throttledSearch(e.target.value)}
                />
              )}

              {/* <button style={{ cursor: "pointer" }} onClick={filterMdas}>
                Search
              </button> */}
            </div>

            {/* <SearchInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            /> */}
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              width: "100%",
              marginBottom: "20px",
              marginTop: "60px",
              justifyContent: "center",
            }}
          >
            {/* <Button style={{ backgroundColor: "#1C811C", color: "white" }}> */}

            <Button
              style={{
                fontSize: "20px",
                padding: "3px 60px",
                height: 50,
                backgroundColor: allMemos ? "#1C811C" : "white",
                color: allMemos ? "white" : "black",
                fontWeight: "bold",
              }}
              onClick={() => setAllMemos(true)}
            >
              All Memos
            </Button>
            <Button
              style={{
                fontSize: "20px",
                padding: "3px 60px",
                height: 50,
                backgroundColor: !allMemos ? "#1C811C" : "white",
                color: !allMemos ? "white" : "black",
                fontWeight: "bold",
              }}
              onClick={() => setAllMemos(false)}
            >
              Others
            </Button>
          </div>
          <div className="flex space-between w-100 primary-color p-15 ">
            <strong className="family">Request Log</strong>
          </div>
          <div className="w-100">
            <Table
              bordered
              pagination={false}
              dataSource={
                allMemos
                  ? requestLogs?.filter((e) => e.moduleName !== "Mail Manager")
                  : requestLogs?.filter((e) => e.moduleName == "Mail Manager")
              }
              columns={requestLogsColums}
              rowClassName="editable-row"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    RedirectToModuleLink(record);
                  },
                };
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              {totalElement > limit && (
                <Pagination
                  showSizeChanger
                  pageSize={limit}
                  onShowSizeChange={paginationChangeHandler}
                  current={current}
                  total={totalElement}
                  // showTotal={true}
                  onChange={paginationChangeHandler}
                  // pageSizeOptions={pageSizeOptions}
                  responsive
                />
              )}
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="flex space-between m-b-20 align-end "></div>
            <Table
              bordered
              dataSource={mdaRequests}
              columns={mdaRequestsColumns}
              rowClassName="editable-row"
            />
          </div>

          <ServiceWidget />
        </>
      )}

      <Modal
        title="Request Details"
        centered
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>{parse(details)}</p>
      </Modal>

      <a
        href="https://greenzonetechnologies.atlassian.net/servicedesk/customer/portals"
        target="_blank"
        rel="noopener noreferrer"
        style={{ position: "absolute", right: "0", bottom: "5px" }}
      >
        <img
          style={{
            position: "fixed",
            bottom: "40px",
            right: "0",
            width: "4rem",
            marginLeft: "auto",
            cursor: "pointer",
            opacity: "0.8",
            transition: "all 0.3s",
          }}
          src={supportIcon}
          alt="Support Icon"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
    </div>
  );
};

export default Dashboard;
