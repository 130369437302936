import axios from "axios";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Loader from "../../assets/svg/loading.svg";
import { post } from "../../services/fetch";
import Notification from "../../utility/notification";

const ForgotPassword = ({ closeForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPassword = async () => {
    setLoading(true);
    axios
      .post(
        `https://eternalapi.edostate.gov.ng/api/resetpassword/send-passwordsms`,
        null,
        {
          params: {
            email,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setLoading(false);
        response.data.code === 1 &&
          Notification({
            title: "Success",
            message: "Your password has been succesfully reset",
            type: "success",
          });

        response.data.code === 1 && closeForgotPassword();

        response.data.code !== 1 &&
          Notification({
            title: "Error",
            message:
              "Sorry, this Email is not mapped to any mobile Number, please check your email and try again",
            type: "danger",
          });
      })
      .catch((err) => {
        setLoading(false);
        Notification({
          title: "Error",
          message:'an error occured',
          type: "danger",
        });
      });
  };
  return (
    <div
      className="overlay p-10 flex-align "
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          maxHeight: "25%",
          marginTop: "30px",
        }}
      >
        <div className="bg-white w-100 m-r-10 p-20">
          <p
            className="bold m-20 "
            style={{ textAlign: "Center", fontSize: "20px" }}
          >
            Reset Your Password
          </p>

          <div className=" m-b-10 ">
            <p>Email: </p>
            <div className="login-form-group" style={{ position: "relative" }}>
              <input
                // style={{ border: "1px solid grey", padding: "10px" }}
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <button
            className="w-100 p-5 pointer ant-radio-checked"
            style={{ border: "1px solid grey", padding: "10px" }}
            placeholder="Please Enter your Email"
            onClick={() => {
              resetPassword();
            }}
            type="button"
            disabled={email === "" || loading}
          >
            Reset Password
            {loading && (
              <img src={Loader} className="btn-loading" alt="Loading..." />
            )}
          </button>
        </div>
      </div>
      <div
        className="pointer"
        onClick={() => {
          closeForgotPassword();
        }}
      >
        <AiOutlineClose
          style={{
            marginLeft: "10px",
            marginTop: "25px",
            backgroundColor: "red",
            width: "40px",
            height: "40px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
