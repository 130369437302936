import React from "react";
import { Switch, Route } from "react-router";
import { pure } from "recompose";
import SessionHOC from "../components/hoc/SessionHOC";
import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "../components/pages/Dashboard";
import DashboardGov from "../components/pages/DashboardGov";
import Settings from "../components/pages/Settings";
import Profile from "../components/pages/Profile";

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/portal-dashboard/"
      exact
      component={SessionHOC(Dashboard)}
    />
    <ProtectedRoute
      path="/portal-dashboard/profile"
      exact
      component={SessionHOC(Profile)}
    />
    <ProtectedRoute
      path="/portal-dashboard/settings"
      exact
      component={SessionHOC(Settings)}
    />
    <ProtectedRoute
      path="/portal-dashboard/gov"
      exact
      component={SessionHOC(DashboardGov)}
    />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
