/* eslint-disable*/
import React, { Component } from "react";
import Parser from "rss-parser";
import Moment from "moment";
import FeedLoader from "react-loader-spinner";
import ServiceWidget from "../layouts/ServiceWidget";

import Loader from "../../assets/svg/loading.svg";
import greenzone from "../../assets/images/download.png";
import logo from "../../assets/images/Group-2.png";
import Footer from "../layouts/Footer";
import supportIcon from '../../assets/images/support-icon.svg'

import { post } from "../../services/fetch";
// import { getLocation } from "../../api-services/otherApis";
import { encodeToken, logoutWithOutRedirect } from "../../utility/auth";
import notification from "../../utility/notification";
import Iframe from "react-iframe";
import Modal from "react-modal";
import {
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineClose,
} from "react-icons/ai";
import ForgotPassword from "../layouts/ForgotPassword";

let parser = new Parser();
const CORS_PROXY = "https://cors-overall.herokuapp.com/";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingin: false,
      isLoggingin2: false,
      email: "",
      password: "",
      allowLogin: true,
      feed: [],
      feedLoader: false,
      modalIsOpen: false,
      modalLink: "",
      inputState: "password",
      newinputState: "password",
      oldinputState: "password",
      confirminputState: "password",
      showPassword: false,
      hider: false,
      hiderConfirmPassword: false,
      hiderNewPassword: false,
      hiderOldPassword: false,
      open: false,
      forgotPasswordModal: false,
      email2: "",
      oldPassword: "",
      password2: "",
      confirmPassword: "",
      setCheck: false,
    };
  }

  handleLogout = async () => {
    await logoutWithOutRedirect();
  };

  showForgotPasswordModal = () => {
    this.setState({
      forgotPasswordModal: true,
      showPassword: false,
    });
  };

  closeForgotPassword = () => {
    this.setState({
      forgotPasswordModal: false,
      showPassword: false,
    });
  };

  async componentDidMount() {
    localStorage.clear();
    localStorage.setItem("firstName", "");
    localStorage.setItem("lastName", "");

    await this.location();

    // this.setState({ feedLoader: true });
    // const guardian = await parser.parseURL(
    //   CORS_PROXY + "https://guardian.ng/tag/edo-state/feed/"
    // );
    // const dailypost = await parser.parseURL(
    //   CORS_PROXY + "https://www.edostate.gov.ng/category/news/feed/"
    // );
    // const vanguard = await parser.parseURL(
    //   CORS_PROXY + "https://www.vanguardngr.com/feed/"
    // );
    // let feed = [];

    // feed.push({ guardian }, { vanguard }, { dailypost });
    // **************************
    const sessionExpired = localStorage.getItem("se");
    // if (sessionExpired) {
    //   notification({
    //     title: "SESSION TIMEOUT",
    //     message:
    //       "Your session token has expired. Please, re-login to start a new session.",
    //     type: "danger",
    //     onRemoval: () => localStorage.removeItem("se"),
    //     duration: 10,
    //   });
    // this.handleLogout();
    // **************************

    // }

    // this.setState({ feed: feed, feedLoader: false });
  }

  questionAnswerArray = [
    {
      q: "1.	How can I have access to EdoGov? ",
      a: "As a staff of your Ministry, Department or Agency (MDA), a username and password will be generated for you upon request and internal approval. These are the details that will be used to log into your personal dashboard on EdoGov.",
    },
    {
      q: "2.	Can I use any browser to access EdoGov?",
      a: "Yes, any of the following browsers can be used to access EdoGov. Google Chrome, Safari, Opera and Firefox ",
    },
    {
      q: "3.	How can I get my username and password?",
      a: "Place a request to your line Director for access to EdoGov.",
    },
    {
      q: "4.	Can users change password?",
      a: "Yes, users can change their password by clicking on the Forgot Password link on the log in page. Enter the required information and click on Reset Password",
    },
    {
      q: "5.	When a monetary request is made and has gone through all the line of approvals how is the money issued?",
      a: ": EdoGov will be integrated with the Oracle application, and the voucher generated from the application will be sent to Oracle automatically for payment to be made. ",
    },
    {
      q: "6.	Can a leave request be edited to change the date?",
      a: "As soon as the leave request is submitted for approval, it cannot be edited to change the date.",
    },
    {
      q: "7.	What if I apply for leave and my first approver isn’t available, will my request be sent automatically to my second approver?",
      a: "If your first approver is not available, raise a request to the Support Manager in your MDA for the approval sequence to be updated.",
    },
    {
      q: "8.	How can I be so sure that my password is secure?",
      a: "The password shared with you is secure, just ensure you keep your password safe and do not share with anyone.",
    },
    {
      q: "9.	Can signature be added when a memo is sent using memo manager?",
      a: "Yes, signature is automatically appended to all memos raised by users. Also, when a memo is approved, the approver’s signature is also appended to the memo.",
    },
    {
      q: "10.	When creating a request using mails manager can I pick two recipients at the same time?",
      a: ": Yes, to select multiple recipients at the same time, you can use the multiple assign feature when assigning the mail.",
    },
    {
      q: "11.	Can EdoGov be accessed when I am not in the office?",
      a: "Yes, it can be accessed using the Virtual Private Network (VPN) client when you are not in the office. To install the VPN client, raise a request to the Support Manager assigned to your MDA",
    },
    {
      q: "12.	If I report a grievance, how can I be so sure that my identity wouldn’t be disclosed? ",
      a: "All grievances raised are treated with confidentiality. The recipient (notwithstanding the hierarchical level) you intend to send the grievance to, can be selected using the application.",
    },
    {
      q: "13.	When sending a request on EdoGov, what if I do not know the name of the recipient, can I search by designation?",
      a: "You can search by any of the names of the recipient and designation.",
    },
  ];

  location = async () => {
    // console.log('hello')
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       const pos = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //       this.setState({ allowLogin: true });

    //       const res = await getLocation(pos);
    //       // console.log(res);

    //       if (res.status === "OK") {
    //         if (res.results[0]) {
    //           const { results } = res;
    //           const neighborhood = results.filter((item) =>
    //             item.types.includes("administrative_area_level_2")
    //           );
    //           // console.log(neighborhood[0].formatted_address);
    //           encodeToken({ gl: neighborhood[0].formatted_address }, "gl");
    //         }
    //       } else {
    //         // console.log(res.error_message);
    //       }
    //     },
    //     (error) => {
    //       // console.log(error);
    //       if (error && error.message === "User denied Geolocation") {
    //         notification({
    //           title: "Geo-Location Denied",
    //           message:
    //             "Sorry, you are required to enable the Geo-Location before you can login. Click on the location icon on your address bar, then allow the site to access your location. Refresh the page to login after you have enabled the Geo-Location.",
    //           type: "danger",
    //           duration: 20000,
    //         });
    //         // this.setState({ allowLogin: false });
    //       }
    //       if (
    //         error &&
    //         error.message.includes("Only secure origins are allowed") &&
    //         process.env.REACT_APP_ENV === "dev"
    //       ) {
    //         this.setState({ allowLogin: true });
    //       }
    //     }
    //   );
    // } else {
    //   notification({
    //     title: "Geo-Location not Supported",
    //     message: "Your browser does not support Geo-Location",
    //     type: "danger",
    //   });
    // }
    this.setState({ allowLogin: true });
  };

  handleKeyPress = (e) => {
    const { isLoggingin } = this.state;
    if (!isLoggingin && e.which === 13) {
      this.handleSignin();
    }
  };

  handleSignin = async () => {
    const { email, password, allowLogin } = this.state;

    if (!allowLogin) {
      notification({
        title: "Geo-Location Not Allowed",
        message:
          "Sorry, you are required to enable the Geo-Location before you can login. Click on the location icon on your address bar, then allow the site to access your location. Refresh the page to login after you have enabled the Geo-Location.",
        type: "danger",
        duration: 20000,
      });
      return;
    }

    if (!(email && password)) {
      notification({
        title: "Invalid Entry",
        message: "Please, enter your email and password.",
        type: "danger",
      });
      return;
    }
    if (
      email &&
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)
    ) {
      notification({
        title: "Invalid Entry",
        message: "Please, enter a valid email",
        type: "danger",
      });
      return;
    }

    this.setState({ isLoggingin: true });

    const res = await post({
      endpoint: "login",
      body: { email, password },
      auth: false,
    });

    this.setState({ isLoggingin: false });

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data);
        this.setState({ isLoggingin: true });
        const { history } = this.props;
        localStorage.setItem("userId", data.data.userId);
        localStorage.setItem("mdaId", data.data.mdaId);
        localStorage.setItem("token", data.token);
        localStorage.setItem("profilePicture", data.data.profilePicture);
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("lastName", data.data.lastName);
        localStorage.setItem("firstName", data.data.firstName);
        localStorage.setItem("staffId", data?.data?.staffId);
        localStorage.setItem("timeOutUrl", data?.data?.timeOutUrl);
        localStorage.setItem("role", data.data.roles[0].name);

        data.token && encodeToken({ t: data.token, mda: data.data.mda }, "t");
        data.data.firstName && encodeToken({ fn: data.data.firstName }, "fn");
        data.data.lastName && encodeToken({ ln: data.data.lastName }, "ln");
        data.data.lastLoginTime &&
          encodeToken({ lo: data.data.lastLoginTime }, "lo");
        data.data.roles && encodeToken({ r: data.data.roles[0].name }, "r");
        data.data.staffId && encodeToken({ staffId: data.data.staffId }, "si");
        // data.data.authenticationToken && encodeToken({ at: data.data.authenticationToken }, 'at');
        encodeToken({ il: true }, "il"); // isLoggedin

        setTimeout(() => history.push("/portal-dashboard"), 2000);

        // console.log('redirect')
      } else {
        notification({
          title: "Login Error",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network Error",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleViewNews = (link) => {
    this.setState({
      modalLink: link,
      modalIsOpen: true,
    });
  };

  render() {
    const hidePassword = () => {
      this.setState({ hider: !this.state.hider });
      if (!this.state.hider) this.setState({ inputState: "text" });
      else this.setState({ inputState: "password" });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };

    const hideConfirmPassword = () => {
      this.setState({ hiderConfirmPassword: !this.state.hiderConfirmPassword });
      if (!this.state.hiderConfirmPassword)
        this.setState({ confirminputState: "text" });
      else this.setState({ confirminputState: "password" });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };

    const hideNewPassword = () => {
      this.setState({ hiderNewPassword: !this.state.hiderNewPassword });
      if (!this.state.hiderNewPassword)
        this.setState({ newinputState: "text" });
      else this.setState({ newinputState: "password" });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };

    const hideOldPassword = () => {
      this.setState({ hiderOldPassword: !this.state.hiderOldPassword });
      if (!this.state.hiderOldPassword)
        this.setState({ oldinputState: "text" });
      else this.setState({ oldinputState: "password" });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };

    const resetPassword = () => {
      this.setState({ showPassword: true });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };
    const resetPassword2 = () => {
      this.setState({ showPassword: false });

      // console.log('hellyyy')
      // console.log(this.state.hider)
    };

    const checkEquality = () => {
      if (this.state.confirmPassword !== this.state.password2) {
        console.log("hi");
        this.setState({ setCheck: true });
      } else {
        this.setState({ setCheck: false });
      }
    };

    const offFunc = () => {
      this.setState({
        open: !this.state.open,
      });
      // setOpen(false)
    };
    const handleMouseOver = (e) => {
      e.currentTarget.style.width = "7rem";
      e.currentTarget.style.opacity = "1";
    };
  
    const handleMouseOut = (e) => {
      e.currentTarget.style.width = "4rem";
      e.currentTarget.style.opacity = "0.8";
    };

    const postPassword2 = async () => {
      this.setState({ isLoggingin2: false });

      const res = await post({
        endpoint: "services/api/resetpassword/resetpassword",
        body: {
          otp: "string",
          email: this.state.email2,
          oldPassword: this.state.oldPassword,
          password: this.state.password2,
          confirmPassword: this.state.confirmPassword,
        },
        auth: false,
      });
      if (res && res.status === 200) {
        notification({
          title: "Success",
          message: "Your password has been succesfully reset",
          type: "success",
        });
        resetPassword2();
      }
    };

    const funcWin = () => {
      window.open(
        "https://erpapi.edostate.gov.ng/tutor/",
        "newwindow",
        "width=900,height=750"
      );
    };

    const {
      isLoggingin,
      isLoggingin2,
      email,
      password,
      feed,
      feedLoader,
      modalIsOpen,
    } = this.state;
    return (
      <div className="w-100 p-20">
        <div className="banner">
          <div className="login">
            <div className="coat-of-arm">
              <div><img src={logo} alt="" /></div>
              <img className="m-b-10" src={greenzone} alt="" />
              <h1 style={{color:"green", fontWeight:"bolder"}}>GREENGOV</h1>
              {/* <h4>GOVERNMENT</h4> */}
            </div>
            <div className="form">
              <div className="login-form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              </div>
              <div
                className="login-form-group"
                style={{ position: "relative" }}
              >
                <input
                  type={this.state.inputState}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />

                <div className="login-form-groupz" onClick={hidePassword}>
                  {this.state.hider ? (
                    <AiOutlineEye style={{ fontSize: "25px" }} />
                  ) : (
                    <AiOutlineEyeInvisible style={{ fontSize: "25px" }} />
                  )}
                </div>
              </div>

              <button
                className="m-b-10 pointer p-r"
                type="button"
                onClick={this.handleSignin}
                disabled={isLoggingin}
              >
                LOGIN
                {isLoggingin && (
                  <img src={Loader} className="btn-loading" alt="Loading..." />
                )}
              </button>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <a
                  className="pointer"
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={resetPassword}
                >
                  Change password?
                </a>

                <p style={{ textAlign: "center", marginLeft: "" }}>
                  {" "}
                  <a
                    className="pointer"
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    FAQs
                  </a>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <a
                  className="pointer"
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={() => {
                    this.showForgotPasswordModal();
                  }}
                >
                  Forgot Password?
                </a>
                <a
                  className="pointer"
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={funcWin}
                >
                  Video guide
                </a>
              </div>
              {this.state.forgotPasswordModal && (
                <ForgotPassword
                  closeForgotPassword={this.closeForgotPassword}
                />
              )}
            </div>
          </div>
        </div>
        <section>
          {this.state.showPassword && (
            <div
              className="overlay p-10 flex-align"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                  maxHeight: "45%",
                  marginTop: "30px",
                }}
              >
                <div className="bg-white w-100 m-r-10 p-20">
                  <p
                    className="bold m-20 "
                    style={{ textAlign: "Center", fontSize: "20px" }}
                  >
                    Change Your Password
                  </p>

                  <div className=" m-b-10 ">
                    {/* <p>Email: </p> */}
                    <div
                      className="login-form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        // style={{ border: "1px solid grey", padding: "10px" }}
                        value={this.state.email2}
                        placeholder="Email"
                        onChange={(e) =>
                          this.setState({ email2: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="m-b-10 ">
                    {/* <p>Old Password: </p> */}

                    <div
                      className="login-form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={this.state.oldinputState}
                        placeholder="Old Password"
                        name="password"
                        value={this.state.oldPassword}
                        // onChange={this.handleChange}
                        onChange={(e) =>
                          this.setState({ oldPassword: e.target.value })
                        }
                        onKeyPress={this.handleKeyPress}
                      />

                      <div
                        className="login-form-groupz"
                        onClick={hideOldPassword}
                      >
                        {this.state.hiderOldPassword ? (
                          <AiOutlineEye style={{ fontSize: "25px" }} />
                        ) : (
                          <AiOutlineEyeInvisible style={{ fontSize: "25px" }} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="m-b-10 ">
                    {/* <p>New Password: </p> */}

                    <div
                      className="login-form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={this.state.newinputState}
                        placeholder="New Password"
                        name="password"
                        value={this.state.password2}
                        // onChange={this.handleChange}
                        onChange={(e) =>
                          this.setState({ password2: e.target.value })
                        }
                        onKeyPress={this.handleKeyPress}
                      />

                      <div
                        className="login-form-groupz"
                        onClick={hideNewPassword}
                      >
                        {this.state.hiderNewPassword ? (
                          <AiOutlineEye style={{ fontSize: "25px" }} />
                        ) : (
                          <AiOutlineEyeInvisible style={{ fontSize: "25px" }} />
                        )}
                      </div>
                    </div>
                    {/* <input className='w-100 p-5' style={{border:"1px solid grey", padding:"10px"}} value = {this.state.password2}
           onChange={(e)=>this.setState({password2:e.target.value})}/> */}
                  </div>

                  <div className="m-b-10 ">
                    {/* <p>Confirm New Passord: </p> */}

                    <div
                      className="login-form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={this.state.confirminputState}
                        placeholder="Confirm New Password"
                        name="password"
                        value={this.state.confirmPassword}
                        // value = {confirmPassword}
                        // onChange={this.handleChange}
                        onChange={(e) => {
                          // this.setCheck()
                          this.setState(
                            { confirmPassword: e.target.value },
                            () => {
                              checkEquality();
                            }
                          );
                        }}
                        onKeyPress={this.handleKeyPress}
                      />

                      <div
                        className="login-form-groupz"
                        onClick={hideConfirmPassword}
                      >
                        {this.state.hiderConfirmPassword ? (
                          <AiOutlineEye style={{ fontSize: "25px" }} />
                        ) : (
                          <AiOutlineEyeInvisible style={{ fontSize: "25px" }} />
                        )}
                      </div>
                    </div>
                    <button
                      className="w-100 p-5 pointer ant-radio-checked"
                      style={{ border: "1px solid grey", padding: "10px" }}
                      onClick={postPassword2}
                      type="button"
                      disabled={isLoggingin2}
                    >
                      Reset Password
                      {isLoggingin2 && (
                        <img
                          src={Loader}
                          className="btn-loading"
                          alt="Loading..."
                        />
                      )}
                    </button>

                    {this.state.confirmPassword !== "" &&
                      this.state.setCheck && (
                        <i style={{ color: "red", textDecoration: "" }}>
                          Passwords do not match
                        </i>
                      )}

                    {/* <input className='w-100 p-5' style={{border:"1px solid grey", padding:"10px"}} value = {this.state.confirmPassword}
           onChange={(e)=>this.setState({confirmPassword:e.target.value})}/> */}
                  </div>
                </div>

                <div onClick={resetPassword2}>
                  <p
                    className="pointer"
                    style={{
                      fontSize: "25px",
                      color: "white",
                      padding: "10px",
                      backgroundColor: "red",
                    }}
                  >
                    X
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="intro center-text content">
            <div>
              <p>
                Mission: Design and delivery of innovative solutions and Digital
                services that enable Edo State Government exceed its strategic
                goals and priorities and position the State for sustainable and
                future growth
              </p>
            </div>
          </div>
        </section>
        <section>
          <div
            className="content"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="row">
              {feedLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FeedLoader
                    type="Grid"
                    color="green"
                    height={100}
                    width={100}
                  />
                </div>
              ) : (
                <>
                  {feed.map((item, i) => (
                    <div className="col-4" key={i}>
                      <div className="dept-news">
                        <h4>RECENT NEWS</h4>
                        <div className="headline-list">
                          {item["guardian"] &&
                            item["guardian"].items
                              .slice(0, 4)
                              .map((guardianNews, i) => (
                                <div className="headline flex m-t-20" key={i}>
                                  <div>
                                    <div className="date">
                                      <p className="m">
                                        {Moment(
                                          guardianNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("MMMM")}
                                      </p>
                                      <p className="d">
                                        {Moment(
                                          guardianNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("D")}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="title">{guardianNews.title}</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "blue",
                                      }}
                                      href={"#"}
                                      className="title"
                                      onClick={() =>
                                        this.handleViewNews(guardianNews.link)
                                      }
                                    >
                                      View
                                    </a>
                                  </div>
                                </div>
                              ))}

                          {item["dailypost"] &&
                            item["dailypost"].items
                              .slice(0, 4)
                              .map((dailypostNews, i) => (
                                <div className="headline flex m-t-20" key={i}>
                                  <div>
                                    <div className="date">
                                      <p className="m">
                                        {Moment(
                                          dailypostNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("MMMM")}
                                      </p>
                                      <p className="d">
                                        {Moment(
                                          dailypostNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("D")}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="title">{dailypostNews.title}</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "blue",
                                      }}
                                      href={"#"}
                                      className="title"
                                      onClick={() =>
                                        this.handleViewNews(dailypostNews.link)
                                      }
                                    >
                                      View
                                    </a>
                                  </div>
                                </div>
                              ))}

                          {item["vanguard"] &&
                            item["vanguard"].items
                              .slice(0, 4)
                              .map((vangaurdNews, i) => (
                                <div className="headline flex m-t-20" key={i}>
                                  <div>
                                    <div className="date">
                                      <p className="m">
                                        {Moment(
                                          vangaurdNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("MMMM")}
                                      </p>
                                      <p className="d">
                                        {Moment(
                                          vangaurdNews.isoDate,
                                          "YYYY/MM/DD"
                                        ).format("D")}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="title">{vangaurdNews.title}</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "blue",
                                      }}
                                      href={"#"}
                                      className="title"
                                      onClick={() =>
                                        this.handleViewNews(vangaurdNews.link)
                                      }
                                    >
                                      View
                                    </a>
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: !modalIsOpen })}
          contentLabel="RECENT NEWS"
        >
          <button onClick={() => this.setState({ modalIsOpen: !modalIsOpen })}>
            close
          </button>
          <p>
            <Iframe
              url={this.state.modalLink}
              width="100%"
              height="700px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </p>
        </Modal>
        <section>
          <div className="content">
            <div className="row">
              <div className="col-3">
                <div className="bottom">
                  <h2>Vision Statement</h2>
                  <p>
                    To make Edo State an Economic hub and No 1 choice for
                    investors both local and foreign.
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Mission Statement</h2>
                  <p>
                    To develop a modern and progressive Edo State where every
                    citizen is empowered with the opportunity to live life in
                    its fullness.
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Corporate Strategy</h2>
                  <ol>
                    <li>Social Welfare</li>
                    <li>Institutional Reform</li>
                    <li>Infrastructural Development</li>
                    <li>Environmental Sustainability</li>
                    <li>Economic Revolution</li>
                    <li>Culture & Tourism </li>
                  </ol>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Resource Centre</h2>
                  <ul>
                    <li>edostate.gov.ng</li>
                    <li>godwinobaseki.com</li>
                    <li>edojobs.edostate.gov.ng</li>
                    <li>eirs.org</li>
                    <li>edogis.org</li>
                    <li>Edojudiciary.org</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <ServiceWidget />

        {this.state.open && (
          <div className="overlay" style={{ transition: "all 1s ease-in" }}>
            <div
              className="flex"
              style={{ margin: "0 auto", justifyContent: "center" }}
            >
              <div
                className="m-t-20  p-10"
                style={{
                  backgroundColor: "white",
                  width: "60%",
                  minHeight: "10px",
                  border: "2px solid",
                }}
              >
                <p
                  className="p-20 bold-text"
                  style={{ fontSize: "35px", textAlign: "center" }}
                >
                  Frequently Asked Questions (FAQ)
                </p>

                {this.questionAnswerArray.map((e, idx) => (
                  <div className="m-t-10 border bg-white">
                    <div
                      className="flex p-10 "
                      style={{
                        backgroundColor: "#D5FFD5",
                        borderLeft: "5px solid green",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "25px", fontWeight: "10px" }}>
                        Q.{" "}
                      </p>
                      <p className="p-10 bold">{e.q}</p>
                    </div>
                    <div
                      style={{
                        opacity: "0.5",
                        borderLeft: "5px solid green",
                        alignItems: "center",
                      }}
                      className="flex p-10"
                    >
                      <p style={{ fontSize: "25px", fontWeight: "10px" }}>
                        A.{" "}
                      </p>
                      <p className="p-10" style={{ opacity: "0.9" }}>
                        {e.a}{" "}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="pointer"
                onClick={() => {
                  this.setState({
                    open: !this.state.open,
                  });
                }}
              >
                <AiOutlineClose
                  style={{
                    marginLeft: "10px",
                    marginTop: "25px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                />
              </div>
            </div>
          </div>
        )}
          <a
        href="https://greenzonetechnologies.atlassian.net/servicedesk/customer/portals"
        target="_blank"
        rel="noopener noreferrer"
        style={{ position: "absolute", right: "0", bottom: "5px" }}
      >
        <img
          style={{
            position: "fixed",
            bottom: "40px",
            right: "0",
            width: "4rem",
            marginLeft: "auto",
            cursor: "pointer",
            opacity: "0.8",
            transition: "all 0.3s",
          }}
          src={supportIcon}
          alt="Support Icon"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      </a>
      </div>
    );
  }
}

export default Home;
