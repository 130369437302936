import axios from "axios";
import Cookies from "js-cookie";

const ModuleName = "Edogov_General_RequestManager";
// const URL = Cookies.get('_APIBaseURL') + '/generalrequest';
const URL = "https://erpapi.edostate.gov.ng/global/api";
// const URL = "https://edogoverp.com/global/api";

const URLDOC = Cookies.get("_APIBaseURL");

export let Access_token =
  Cookies.get(ModuleName + "_AppToken") || Cookies.get("token");

const Http = axios.create({
  baseURL: URL,
  // headers,
});

Http.interceptors.request.use(
  function (config) {
    config.headers["x-api-key"] = "fq05322d-429b-4f77-8a4p-a97ry62eb37k";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Http;
