import Http from "../services/httpService";
import Cookies from "js-cookie";

const empId =
//  544
localStorage.getItem("userId");
const empIdForPa = 543
// 243;

export const DashboardModuleCount = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetDashboardModuleManagerCount`, {
    params: {
      empId,
    },
  });
};

export const EmployeeModules = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetEmployeeModules`, {
    params: {
      empId,
    },
  });
};

export const UpdateEmployeeModules = (payload) => {
  return Http.put(`/PortalDashBoard/PutEmployeeModules`, payload);
};

export const UpdateRequestItem = (payload) => {
  return Http.put(`/PortalDashBoard/UpdateRequestItem`, payload);
};


export const UpdateEmployeeModulesForPA = (payload) => {
  return Http.put(`/PortalDashBoard/PutEmployeeModules`, payload);
};

export const UpdateRequestItemForPA = (payload) => {
  return Http.put(`/PortalDashBoard/UpdateRequestItem`, payload);
};

export const GetModuleLink = (payload) => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.put(`/PortalDashBoard/GetModuleLink?userId=${empId}`, payload);
};

export const GetModuleLinkForPA = (payload) => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.put(`/PortalDashBoard/GetModuleLinkForPa?userId=${empId}`, payload);
};

export const DashboardMainStatistics = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetDashboardMainStatistics`, {
    params: {
      empId,
    },
  });
};

export const DashboardMainStatisticsForPA = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetDashboardMainStatistics`, {
    params: {
      empId,
    },
  });
};

export const DashboardMdaRequestCount = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetDashboardMdaRequestCount`, {
    params: {
      empId,
    },
  });
};

export const DashboardMdaRequestCountForPA = () => {
  const empId = 
  // 544
  localStorage.getItem("userId");
  return Http.get(`/PortalDashBoard/GetDashboardMdaRequestCount`, {
    params: {
      empId,
    },
  });
};

// export const RequestLogs = () => {
//   const empId = localStorage.getItem("userId");
//   return Http.get(`/PortalDashBoard/GetRequestLogs`, {
//     params: {
//       empId,
//     },
//   });
// };

export const RequestLogs = (payload) => {
  return Http.put(`/PortalDashBoard/GetRequestLogs`, payload);
};

export const RequestLogsMail = (payload) => {
  return Http.put(`/PortalDashBoard/GetRequestLogsMail`, payload);
};




export const FilterRequestLogs = (payload, from, to) => {
  return Http.put(
    `/PortalDashBoard/GetFilteredRequestLogs?from=${from}&to=${to}`,
    payload
  );
};


export const RequestLogsForPA = (payload) => {
  return Http.put(`/PortalDashBoard/GetRequestLogsForPa`, payload);
};

export const FilterRequestLogsForPA = (payload, from, to) => {
  return Http.put(
    `/PortalDashBoard/GetFilteredRequestLogsForPa?from=${from}&to=${to}`,
    payload
  );
};
