import React, { useState, useEffect } from "react";

import PortalRoutes from "../routes/PortalRoutes";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import menuList from "../config/sidebarMenu";

import { decodeToken, jwtDecode } from "../utility/auth";
import { get } from "../services/fetch";

function UserPortal(props) {
  const { history } = props;
  const [menuList1, setMenuList] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);

  const mdaId =
  //  28
  localStorage.getItem("mdaId");
  const UserID =
  //  544
  localStorage.getItem("userId");

  /**
   * @ useEffect function
   * On Portal load,
   * Getting userId from token
   * Getting sidebar modules for that user
   */
  useEffect(async () => {
    setLoadingMenu(true);

    const {
      data: { data },
    } = await get({
      endpoint: `rolemodule/api/ModulesDetails/GetModule?mda=${mdaId}&userid=${UserID}`,
      auth: false,
    });

    console.log(data);
    let kk = data.map((dat) => ({
      ...dat,
      module:
        dat &&
        dat.category == "Governor's Module" &&
        // +localStorage.getItem('userId') == 253
        // +localStorage.getItem("userId") == 3509
        +localStorage.getItem("userId") == 543
          ? // +localStorage.getItem("userId") == 3509

            [
              ...dat?.module,
              {
                name: "Governor's Dashboard",
                address: "/portal-dashboard/gov",
              },
            ]
          : [...dat.module],
    }));
    console.log(kk);
    setMenuList(kk);
    setLoadingMenu(false);
  }, []);

  return (
    <div className="container">
      <Sidebar
        history={history}
        loadingMenu={loadingMenu}
        menuList={menuList1}
      />
      <div className="portal">
        <Header navList={["Home", "Notification", "Set Reminder"]} />
        <div className="content main-content">
          <PortalRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UserPortal;
